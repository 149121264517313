import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#333333", // Mörk grå färg för primära element
    },
    secondary: {
      main: "#666666", // Ljusare grå färg för sekundära element
    },
    background: {
      default: "#f0f0f0", // Ljusgrå bakgrundsfärg
    },
    text: {
      primary: "#333333", // Text i mörkgrå färg
      secondary: "#666666", // Sekundär text i ljusare grå
    },
  },
  typography: {
    h1: {
      color: "#333333",
    },
    h2: {
      color: "#333333",
    },
    h4: {
      color: "#333333",
    },
    body1: {
      color: "#666666",
    },
  },
});

export default theme;
