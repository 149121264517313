import React from "react";
import { Container } from "@mui/material";
import ImageTextSection from "../components/ImageTextSection";
import carImage from "../assets/bil_420x320.jpg";
import belysning from "../assets/belysning_420.jpg";

const About = () => {
  return (
    <Container>
      <ImageTextSection
        subheader1="Företaget"
        text1="Företaget grundades hösten 2019 av Magnus Gustafsson och John Smith. Tillsammans har vi över 30 års erfarenhet inom branschen, där våra karriärer tog sin början redan i början av 2000-talet.
            Började inom elinstallations yrket där vi båda har haft rollen som ledande   montör sam projekterat och drivit små som stora projekt.
            Av ren nyfikenhet började vi verka inom kraftmontage med allt va det   innebär.  
            Även inom detta område hade vi ledande roller.
            De senaste 5 åren har vi arbetat inom eldistribution där vi främst har haft 
            hand om elnät"
        subheader2="Vår affärsidé"
        text2="Vår affärsidé är att tillhandahålla helhets baserade lösningar till kund. Utföra projektering, konsultuppdrag, service och installation med hög kvalité inom elbranschen.
            Vår vision är att utveckla Kinta AB till ett av de bästa företagen inom verksamhetsområdet.
            Med kundfokus, ständiga förbättringar inom företaget är vår vision att vara en attraktiv entreprenör."
        imageUrl={carImage}
      />

      <ImageTextSection
        subheader1="Våra tjänster"
        text1="Vi löser det mesta inom el och inom bygg. 
        Vi har erfarna elektriker som utför det mesta inom hög och lågspänning, 
        solcellsanläggningar, elbilsladdare, ställverk och eljour. 
        Inom bygg utvör våra duktiga snickare uppdrag som nybyggnation, byggservice, snickerier, 
        finsnickerier och totalentreprenad. "
        imageUrl={belysning}
      />
    </Container>
  );
};

export default About;
