import React from "react";
import { Box, Typography } from "@mui/material";

const TextImageSection = ({ title, text, imageUrl, imageFirst }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: imageFirst ? "row-reverse" : "row" }, // Växla ordning baserat på imageFirst-prop
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "40px",
      }}
    >
      <Box
        sx={{
          flex: 1,
          padding: "20px",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold", marginBottom: "10px" }}
        >
          {title}
        </Typography>
        <Typography variant="body1">{text}</Typography>
      </Box>

      <Box sx={{ flex: 1, padding: "20px" }}>
        <img
          src={imageUrl}
          alt={title}
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "4px",
          }}
        />
      </Box>
    </Box>
  );
};

export default TextImageSection;
