import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Container,
} from "@mui/material";

import lidkoping_elnat from "../assets/logo-lidkoping_elnat.png";
import rejlers_logo from "../assets/Rejlers_logo.svg";
import intea_logo from "../assets/intea_logo.svg";
import alloys_logo from "../assets/alloys_logo.png";
import jnp_logo from "../assets/jnp_logo.png";


const References = () => {
  // Här kan du lägga till referenskunder och samarbetspartners som objekt
  const referenceClients = [
    {
      name: "JNP förvaltning",
      description: "",
      logo: jnp_logo, // Lägg till en väg till kundens logotyp
    },
    {
      name: "Vargön Alloys",
      description: "",
      logo: alloys_logo, // Lägg till en väg till kundens logotyp
    },
    {
      name: "Intea - Fastigheter",
      description: "",
      logo: intea_logo, // Lägg till en väg till kundens logotyp
    },
    {
      name: "Rejlers",
      description: "",
      logo: rejlers_logo, // Lägg till en väg till kundens logotyp
    },
    {
      name: "Lidköping Elnät",
      description: "",
      logo: lidkoping_elnat,
    },
  ];
/*
  const partners = [
    {
      name: "Partner 1",
      description: "Beskrivning av samarbetspartner 1.",
      logo: "path/to/partner1.jpg", // Lägg till en väg till partnerns logotyp
    },
    {
      name: "Partner 2",
      description: "Beskrivning av samarbetspartner 2.",
      logo: "path/to/partner2.jpg", // Lägg till en väg till partnerns logotyp
    },
    {
      name: "Partner 3",
      description: "Beskrivning av samarbetspartner 3.",
      logo: "path/to/partner3.jpg", // Lägg till en väg till partnerns logotyp
    },
    {
      name: "Partner 4",
      description: "Beskrivning av samarbetspartner 4.",
      logo: "path/to/partner4.jpg", // Lägg till en väg till partnerns logotyp
    },
   
  ];*/

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Samarbetspartner och referenskunder
      </Typography>
      <Typography variant="body1">
        Vi är stolta över att samarbeta med ledande företag och organisationer
        inom både el- och byggbranschen. Tillsammans levererar vi högkvalitativa
        lösningar och skapar långsiktiga relationer. Våra samarbeten sträcker
        sig över flera sektorer, och vi är glada att få bidra till framgångarna
        för våra kunder och partners.
      </Typography>
      <Box sx={{padding: 3}}>
        <Grid container spacing={3} justifyContent="center">
          {referenceClients.map((client, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={client.logo}
                  alt={client.name}
                  sx={{
                    objectFit: "contain",
                    filter: "drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4))", // Mjuk skugga runt logon
                    padding: "10px", // Marginal så att skuggan inte klipps av
                  }}
                />
                <CardContent sx={{textAlign: "center"}}>
                  <Typography variant="h6">{client.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {client.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default References;
