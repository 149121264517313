import React from "react";
import {Box, Link, Typography} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const ContactInfo = ({phone, phone2, email, address, googlemaps}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {xs: "column", md: "row"},
        justifyContent: "space-around",
        alignItems: "center",
        textAlign: "center",
        padding: "20px",
        backgroundColor: "#f0f0f0",
        borderRadius: "8px",
      }}
    >
      {/* Telefon */}
      <Box sx={{margin: "10px"}}>
        <PhoneIcon
          sx={{fontSize: "40px", marginBottom: "10px", color: "#333"}}
        />
        <Typography variant="h6">Telefon</Typography>
        <Link href={`tel:${phone}`} color="inherit" underline="none">
          <Typography variant="body1">{phone}</Typography>
        </Link>
        {phone2 && (
          <Link href={`tel:${phone2}`} color="inherit" underline="none">
            <Typography variant="body1">{phone2}</Typography>
          </Link>
        )}
      </Box>

      {/* E-post */}
      <Box sx={{margin: "10px"}}>
        <EmailIcon
          sx={{fontSize: "40px", marginBottom: "10px", color: "#333"}}
        />
        <Typography variant="h6">E-post</Typography>
        <Link href={`mailto:${email}`} color="inherit" underline="none">
          <Typography variant="body1">{email}</Typography>
        </Link>
      </Box>

      {/* Adress */}
      <Box sx={{margin: "10px"}}>
        <LocationOnIcon
          sx={{fontSize: "40px", marginBottom: "10px", color: "#333"}}
        />
        <Typography variant="h6">Adress</Typography>
        <Typography variant="body1">{address}</Typography>
        {googlemaps && (
          <Link
            href={googlemaps}
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            Hitta hit
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default ContactInfo;
