import React, {useState} from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Tabs,
  Tab,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import kintablogo from "../assets/kintab_logo.png";

const Navbar = () => {
  const location = useLocation();
  const currentTab = location.pathname;
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = [
    {label: "Hem", path: "/"},
    {label: "Om oss", path: "/about"},
    {label: "Tjänster", path: "/services"},
    {label: "Kontakt", path: "/contact"},
    {label: "Samarbetspartner", path: "/references"},
  ];

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#f0f0f0",
        color: "#333333",
        marginBottom: "20px",
      }}
    >
      <Toolbar sx={{justifyContent: "center", alignItems: "center"}}>
        <Box sx={{display: "flex", alignItems: "center", flexGrow: 1}}>
          <img
            src={kintablogo}
            alt="Kintab Logo"
            style={{height: "80px", marginRight: "10px", marginTop: "10px"}}
          />
          <Typography
            variant="h5"
            component="div"
            sx={{textTransform: "uppercase"}}
          >
            Kintab
          </Typography>
        </Box>

        {/* Visa hamburgermeny på små skärmar */}
        <Box sx={{display: {xs: "block", md: "none"}}}>
          <IconButton color="inherit" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Visa flikar på större skärmar */}
        <Box sx={{display: {xs: "none", md: "flex"}, justifyContent: "center"}}>
          <Tabs value={currentTab} textColor="inherit" indicatorColor="primary">
            {menuItems.map((item) => (
              <Tab
                key={item.path}
                label={item.label}
                value={item.path}
                component={Link}
                to={item.path}
              />
            ))}
          </Tabs>
        </Box>
      </Toolbar>

      {/* Drawer för mobilmenyn */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              component={Link}
              to={item.path}
              key={item.label}
              onClick={handleDrawerToggle}
            >
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
