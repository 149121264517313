import React from "react";
import { Container, Typography } from "@mui/material";
import TextImageSection from "../components/TextImageSection";
import skap from "..//assets/skap_420.jpg";
import kabel from "..//assets/laddkabel.jpg";
import elskap from "..//assets/elskap.jpg";
import sol from "..//assets/sol.jpg";
import bygg from "..//assets/bygg.jpg";
import underlagg from "..//assets/underlagg.jpg";
import entreprenad from "..//assets/entreprenad.jpg";

const Services = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Våra Tjänster
      </Typography>
      <Typography variant="body1">
        Vi erbjuder och löser det mesta inom både el och bygg. Läs mer nedan
        inom vilka områden vi arbetar.
      </Typography>
      <TextImageSection
        title="Erfarna och pålitliga elektriker – Specialister inom hög- och lågspänning med högspänningsinstallationer upp till 130 kV."
        text="Letar du efter en pålitlig elektriker som kan hantera både komplexa högspänningsinstallationer och precisionskrävande lågspänningsarbeten? Då är du i trygga händer hos oss! På Kintab erbjuder vi helhetslösningar för både företag och privatpersoner, med ett team av certifierade elektriker som har lång erfarenhet och expertis inom både hög- och lågspänning. Oavsett om du behöver hjälp med större industriinstallationer, elcentraler, eller mer detaljinriktade uppdrag som belysning och smarta hem-system, levererar vi alltid arbete av högsta kvalitet."
        imageUrl={elskap}
        imageFirst={false} // Bilden visas till höger
      />
      <TextImageSection
        title="Ställverk och eljour – Säkerhet och effektivitet i alla energiflöden"
        text="Ett ställverk är hjärtat i ditt elsystem och spelar en avgörande roll för att säkra en pålitlig och effektiv kraftdistribution. Vi erbjuder ställverkslösningar som är skräddarsydda efter dina specifika behov, oavsett om det handlar om industri, fastigheter eller större energianläggningar. Vi erbjuder även snabb och professionell eljour som är tillgänglig 24/7."
        imageUrl={skap}
        imageFirst={false} // Bilden visas till höger
      />
      <TextImageSection
        title="Solenergi – Din väg till hållbar och kostnadseffektiv energi"
        text="Vill du sänka dina energikostnader och samtidigt göra en insats för miljön? Då är solenergi det perfekta valet! På Kintab erbjuder vi kompletta solenergilösningar som hjälper dig att utnyttja kraften från solen och omvandla den till ren, hållbar el för ditt hem eller din verksamhet"
        imageUrl={sol}
        imageFirst={false} // Bilden visas till höger
      />
      <TextImageSection
        title="Ladda bil"
        text="Behöver du en elbilsladdare eller laddkabel? Vi har flera olika modeller och märken. Vi installerar alla och har ni preferenser ring oss då vi har bra priser på elbilsladdare."
        imageUrl={kabel}
        imageFirst={false} // Bilden visas till höger
      />
      <TextImageSection
        title="Nybyggnation och byggservice – Din partner för hållbart och kvalitativt byggande"
        text="På Kintab erbjuder vi helhetslösningar inom nybyggnation och byggservice, där vi kombinerar erfarenhet, innovation och hållbarhet för att förverkliga dina byggprojekt. Oavsett om du planerar att bygga en ny villa, ett kommersiellt fastighetsprojekt eller behöver löpande byggservice och underhåll, är vi din pålitliga partner genom hela processen."
        imageUrl={bygg}
        imageFirst={false} // Bilden visas till höger
      />
      <TextImageSection
        title="Totalentreprenad – Komplett ansvar från start till mål"
        text="Kintab erbjuder en helhetslösning där vi tar fullt ansvar för hela ditt projekt från början till slut. Oavsett om du planerar en nybyggnation, ombyggnad eller renovering, ser vi till att allt löper smidigt och effektivt, från projektering och planering till färdigställande och slutbesiktning."
        imageUrl={entreprenad}
        imageFirst={false} // Bilden visas till höger
      />
      <TextImageSection
        title="Snickerier och finsnickerier – Hantverk med precision och känsla för detaljer"
        text="Hos oss erbjuder vi högkvalitativa snickerier och finsnickerier, skapade med passion för hantverket och ett öga för detaljer. Oavsett om du behöver hjälp med större snickeriprojekt som inbyggda möbler, specialanpassade kök, eller mer detaljerade finsnickeriarbeten som dörrar, lister och exklusiva trädetaljer, så levererar vi alltid med fokus på kvalitet och estetik."
        imageUrl={underlagg}
        imageFirst={false} // Bilden visas till höger
      />
    </Container>
  );
};

export default Services;
