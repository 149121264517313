import React from "react";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Link,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import EngineeringIcon from "@mui/icons-material/Engineering";

// Exempeldata för anställda
const employees = [
  {
    name: "Magnus Gustafsson",
    title: "Projektledare Elkraft",
    phone: "+46 73 612 06 40",
    email: "magnus@kintab.se",
  },
  {
    name: "John Smith",
    title: "Projektledare Bygg/Elkraft",
    phone: "+46 73 021 73 90",
    email: "john@kintab.se",
  },
  {
    name: "Tobias Hellberg",
    title: "Projektledare/Montör Elkraft",
    phone: "+46 73 049 53 32",
    email: "tobias@kintab.se",
  },
  {
    name: "Mattias Seffner",
    title: "Projektledare/Montör El",
    phone: "+46 70 232 93 91",
    email: "seffner@kintab.se",
  },
  {
    name: "Jörgen Hallèn",
    title: "Elektriker",
    phone: "+46 76 140 19 54",
    email: "jorgen@kintab.se",
  },
  {
    name: "Dennis Odlè",
    title: "Elektriker",
    phone: "+46 76 140 19 56",
    email: "dennis@kintab.se",
  },
  {
    name: "Alexander Sandberg",
    title: "Elektriker",
    phone: "+46 73 660 12 67",
    email: "alexander@kintab.se",
  },
  {
    name: "Andreas Larsson",
    title: "Projektledare/Montör El",
    phone: "+46 70 039 56 26",
    email: "andreas@kintab.se",
  },
  {
    name: "Mikael Stenström",
    title: "Snickare",
    phone: "+46 76 140 19 52",
    email: "mikael@kintab.se",
  },
  {
    name: "Martin Kjell",
    title: "Snickare",
    phone: "+46 70 750 72 42",
    email: "martin@kintab.se",
  },
];

// Funktion för att hämta initialer från namn
const getInitials = (name) => {
  const nameParts = name.split(" ");
  return nameParts.length > 1
    ? `${nameParts[0][0]}${nameParts[1][0]}`
    : nameParts[0][0];
};

const ContactList = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Kontakt
      </Typography>
      <Typography variant="body1" sx={{marginBottom: 4}}>
        Här är våra kontaktuppgifter. Tveka inte att höra av dig till någon av
        oss!
      </Typography>

      {/* Lista med anställda */}
      <List>
        {employees.map((employee, index) => (
          <ListItem
            key={index}
            sx={{
              padding: "12px 0",
              borderBottom: "1px solid #e0e0e0",
              alignItems: "center", // Centrerar avataren vertikalt
            }}
          >
            {/* Avatar till vänster om text */}
            <Avatar
              sx={{
                bgcolor: "#b0bec5",
                color: "#37474f",
                width: 64, // Ökad storlek
                height: 64, // Ökad storlek
                fontSize: "1.3rem",
                marginRight: 2, // Avstånd mellan avatar och text
              }}
            >
              {getInitials(employee.name)}
            </Avatar>

            {/* Namn och kontaktuppgifter */}
            <ListItemText
              primary={employee.name}
              primaryTypographyProps={{fontWeight: "bold"}}
              secondary={
                <>
                  {/* Titel */}
                  {employee.title && (
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 0.5,
                        color: "#555",
                      }}
                    >
                      <EngineeringIcon
                        fontSize="small"
                        sx={{marginRight: 0.5}}
                      />
                      {employee.title}
                    </Typography>
                  )}

                  {/* Telefonnummer */}
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 0.5,
                      color: "#333",
                    }}
                  >
                    <PhoneIcon fontSize="small" sx={{marginRight: 0.5}} />
                    <Link
                      href={`tel:${employee.phone}`}
                      color="inherit"
                      underline="none"
                    >
                      {employee.phone}
                    </Link>
                  </Typography>

                  {/* E-post */}
                  {employee.email && (
                    <Typography
                      variant="body2"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 0.5,
                        color: "#333",
                      }}
                    >
                      <EmailIcon fontSize="small" sx={{marginRight: 0.5}} />
                      <Link
                        href={`mailto:${employee.email}`}
                        color="inherit"
                        underline="none"
                      >
                        {employee.email}
                      </Link>
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default ContactList;